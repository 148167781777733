export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: true,
  locales: [{"code":"en","name":"English","iso":"en","file":"en.js"},{"code":"de","name":"Deutsch","iso":"de","file":"de.js"},{"code":"es","name":"Español","iso":"es","file":"es.js"},{"code":"fr","name":"Français","iso":"fr","file":"fr.js"},{"code":"pt","name":"Português","iso":"pt","file":"pt.js"},{"code":"it","name":"Italiano","iso":"it","file":"it.js"},{"code":"nl","name":"Dutch","iso":"nl","file":"nl.js"},{"code":"ja","name":"日本語","iso":"ja","file":"ja.js"},{"code":"zh","name":"简体中文","iso":"zh","file":"zh.js"},{"code":"ko","name":"한국어","iso":"ko","file":"ko.js"},{"code":"sv","name":"Svenska","iso":"sv","file":"sv.js"},{"code":"tr","name":"Türkçe","iso":"tr","file":"tr.js"},{"code":"id","name":"Bahasa Indonesia","iso":"id","file":"id.js"},{"code":"pl","name":"Polish","iso":"pl","file":"pl.js"},{"code":"en-in","name":"English (India)","iso":"en-IN","file":"en.js","disabled":true},{"code":"en-id","name":"English (Indonesia)","iso":"en-ID","file":"en.js","disabled":true},{"code":"en-ph","name":"English (Philippines)","iso":"en-PH","file":"en.js","disabled":true},{"code":"en-br","name":"English (Brazil)","iso":"en-BR","file":"en.js","disabled":true},{"code":"en-my","name":"English (Malaysia)","iso":"en-MY","file":"en.js","disabled":true}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/app/src/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://www.freeconvert.com",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  skipNuxtState: true,
  normalizedLocales: [{"code":"en","name":"English","iso":"en","file":"en.js"},{"code":"de","name":"Deutsch","iso":"de","file":"de.js"},{"code":"es","name":"Español","iso":"es","file":"es.js"},{"code":"fr","name":"Français","iso":"fr","file":"fr.js"},{"code":"pt","name":"Português","iso":"pt","file":"pt.js"},{"code":"it","name":"Italiano","iso":"it","file":"it.js"},{"code":"nl","name":"Dutch","iso":"nl","file":"nl.js"},{"code":"ja","name":"日本語","iso":"ja","file":"ja.js"},{"code":"zh","name":"简体中文","iso":"zh","file":"zh.js"},{"code":"ko","name":"한국어","iso":"ko","file":"ko.js"},{"code":"sv","name":"Svenska","iso":"sv","file":"sv.js"},{"code":"tr","name":"Türkçe","iso":"tr","file":"tr.js"},{"code":"id","name":"Bahasa Indonesia","iso":"id","file":"id.js"},{"code":"pl","name":"Polish","iso":"pl","file":"pl.js"},{"code":"en-in","name":"English (India)","iso":"en-IN","file":"en.js","disabled":true},{"code":"en-id","name":"English (Indonesia)","iso":"en-ID","file":"en.js","disabled":true},{"code":"en-ph","name":"English (Philippines)","iso":"en-PH","file":"en.js","disabled":true},{"code":"en-br","name":"English (Brazil)","iso":"en-BR","file":"en.js","disabled":true},{"code":"en-my","name":"English (Malaysia)","iso":"en-MY","file":"en.js","disabled":true}],
  localeCodes: ["en","de","es","fr","pt","it","nl","ja","zh","ko","sv","tr","id","pl","en-in","en-id","en-ph","en-br","en-my"],
}

export const localeMessages = {
  'en.js': () => import('../../src/locales/en.js' /* webpackChunkName: "lang-en.js" */),
  'de.js': () => import('../../src/locales/de.js' /* webpackChunkName: "lang-de.js" */),
  'es.js': () => import('../../src/locales/es.js' /* webpackChunkName: "lang-es.js" */),
  'fr.js': () => import('../../src/locales/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'pt.js': () => import('../../src/locales/pt.js' /* webpackChunkName: "lang-pt.js" */),
  'it.js': () => import('../../src/locales/it.js' /* webpackChunkName: "lang-it.js" */),
  'nl.js': () => import('../../src/locales/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'ja.js': () => import('../../src/locales/ja.js' /* webpackChunkName: "lang-ja.js" */),
  'zh.js': () => import('../../src/locales/zh.js' /* webpackChunkName: "lang-zh.js" */),
  'ko.js': () => import('../../src/locales/ko.js' /* webpackChunkName: "lang-ko.js" */),
  'sv.js': () => import('../../src/locales/sv.js' /* webpackChunkName: "lang-sv.js" */),
  'tr.js': () => import('../../src/locales/tr.js' /* webpackChunkName: "lang-tr.js" */),
  'id.js': () => import('../../src/locales/id.js' /* webpackChunkName: "lang-id.js" */),
  'pl.js': () => import('../../src/locales/pl.js' /* webpackChunkName: "lang-pl.js" */),
}
